.InputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > span {
    display: block;
    color: red;
    font-size: 14px;
  }

  & > label {
    display: contents;
    color: rgba(34, 34, 34, 1);
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.Input[type='text'] {
  padding: 14px 16px;
  line-height: 16px;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid rgba(169, 170, 167, 1);
}
